import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import './Header.css';


const Header = () => {
    const { t, i18n } = useTranslation();
    return (
            <div className='header'>
            { i18n.language === 'en' && <Button className='demo' variant="outline-warning" onClick={() => {
                i18n.changeLanguage('ar')
            }}>AR</Button>}
            { i18n.language === 'ar'&& <Button className='dem'  variant="outline-warning"  onClick={() => {
                i18n.changeLanguage('en');
            }}>EN</Button>}
        </div>
    )
}

export default Header; 