import React, { useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown';
import { GearFill } from "react-bootstrap-icons";
import './Dark.css';
import './Price.css';

const Price = () => {
    const [data,setData] = useState({})
    const [value, setValue] = React.useState("")
    const [selects, setSelects] = useState();
    const { t, i18n } = useTranslation();

    const handleInputChange = (e) => {
        const input = e.target.value;
        console.log(input);
        const parsedValue = parseInt(input, 10);
        console.log(parsedValue);
        setValue(isNaN(parsedValue) ? input : parsedValue);
    };

    const getData=()=>{
        fetch("https://www.goldapi.io/api/XAU/EGP"
        ,{
        headers : { 
            'x-access-token': 'goldapi-13rd50tlbv80jkx-io ',
            'Content-Type': 'application/json',
        }})
        .then(function(response){
            setData(response)
            return response.json();
        })
        .then(function(myJson) { 
            setData(myJson)
        });
    }
    useEffect(()=>{
        getData()
    },[])
    
    return (
        <div className='gram' dir="auto">
            <h1>{t('title')}</h1>
            <div className='num'>
                <h3>{t('18')}: <span>{data.price_gram_18k?.toFixed()} {t('EGP')}</span></h3>
                <h3>{t('20')}: <span>{data.price_gram_20k?.toFixed()} {t('EGP')}</span></h3>
                <h3>{t('21')}: <span>{data.price_gram_21k?.toFixed()} {t('EGP')}</span> </h3>
                <h3>{t('24')}: <span>{data.price_gram_24k?.toFixed()} {t('EGP')}</span></h3>
            </div>
            <div className='per'>
                <div>
                    <h1>{t('Buy')}</h1>
                </div>
                <div>
                    <h5>{t('W')}</h5>
                    <Dropdown value={selects} onChange={e=> setSelects(e.target.value)} >
                        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                            <GearFill />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <select value={selects} onChange={e=> setSelects(e.target.value)} >
                                <option>{t('cal')}</option>
                                <option value={data.price_gram_24k?.toFixed()}>24</option>
                                <option value={data.price_gram_21k?.toFixed()}>21</option>
                                <option value={data.price_gram_20k?.toFixed()}>20</option>
                                <option value={data.price_gram_18k?.toFixed()}>18</option>
                            </select>
                        </Dropdown.Menu>
                    </Dropdown>
                    <input type="number"  value={value} pattern="\d+" onChange={handleInputChange} name="initialValue" id="initialValue" placeholder={t('G')}/>
                    <div className='child'>
                        <h5>{t('P')}</h5>
                        <span className='two' value={selects} onChange={e=> setSelects(e.target.value)}>{value* selects}</span>
                    </div>
                </div>
            </div>
        </div>
    )
    
}

export default Price;